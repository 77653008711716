<template>
  <div class="trailer">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/cgcb_ceEnDI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'TrailerView'
}
</script>

<style>



.trailer {
  background-color: transparent;
  background-image: url("@/assets/desertfloor.png");
  width: 100%;
  max-width: 100vw;
  min-height: 450px;
  margin-top: -20px;
  z-index: 1;
  padding-top: 4em;
  box-sizing: border-box;
}

</style>