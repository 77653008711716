<template>
  <div class="landing">
    <div class="video-container" ref="videocontainer">
      <!-- <iframe ref="video" src="https://www.youtube.com/embed/cgcb_ceEnDI?autoplay=1&mute=1&controls=0&loop=1&rel=0&modestbranding=1&showinfo=0"></iframe> -->
      <video ref="video" autoplay loop muted>
        <source src="@/assets/videos/nawarak_loop.webm" type="video/webm" />
      </video>
    </div>
    <div class="video-block"></div>
    <div class="intro-text">
      <div class="text">
       <div class="logo"></div>
        <p>
          <b>Nawarak</b> is a co-op realtime strategy game where you guide your own caravan through an arid desert.
        </p>
        <p ref="registernotice" v-if="!registered">
          Enter your email to sign up for updates and future tests
        </p>
        <p ref="registerthanks" class="registered" v-if="registered">Thanks for registering!</p>
      </div>
      <div class="newsletter-form" ref="registerform" v-if="!registered">
        <form action="#">
          <input type="email" name="email" ref="email" placeholder="Enter your e-mail address" required /><input type="submit" value="Sign up" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  data() {
    return {
      registered: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.$refs.video.play();

    this.$refs.registerform.addEventListener('submit', this.handleSubmit);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let screenPosY = this.$refs.videocontainer.getBoundingClientRect().top;
      if (screenPosY <= 0) {
        this.$refs.video.style.marginTop = (-screenPosY * 0.25) + "px";
      }
    },
    handleSubmit(event) {
      event.preventDefault();

      var data = new FormData();
      data.append('email', this.$refs.email.value);
      fetch("https://api.nawarak.com/api/v1/newsletter", { method: 'POST', body: data });

      this.$data.registered = true;
      return false;
    }
  }
}


</script>

<style>

.logo {
  background-image: url('@/assets/nawarak_logo.png');
  width: 200px;
  height: 100px;
  background-size: 100% 100%;
  margin: 0 auto 1em auto;
}

.landing {
  position: relative;
  background-color: #000;
  width: 100vw;
  aspect-ratio: 21 / 9;
  min-height: 760px;
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: -20%;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.video-block {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-container iframe, .video-container video {
  position: relative;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.intro-text {
  position: relative;
  left: 12vw;
  top: 10vh;
  width: 450px;
  box-sizing: border-box;
  height: auto;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 1em 1em;
  font-size: 32px;
  box-shadow: 0 0 10px 0px rgba(0,0,0,0.4);
}

.intro-text .text {
  flex: 1;
  text-align: left;
  color: rgb(102, 84, 74);
}

.intro-text .text p {
  margin-top: 0;
}

.intro-text .newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.registered {
  color: rgba(37,157,170,1);
  font-weight: bold;
}

.newsletter-form form {
  display: flex;
  flex: 1;
}

.newsletter-form input {
  font-family: 'Dosis', sans-serif;
  font-size: 18px;
}

.newsletter-form input[type="email"] {
  border: none;
  border-radius: 5px;
  background-color: #d6b6a0;
  padding: 0.8em 1em;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.4);
  flex: 1;
}

.newsletter-form input[type="submit"] {
  margin-left: 1em;
  border: none;
  border-radius: 5px;
  color: #fff;
  background: rgb(37,157,170);
  background: linear-gradient(0deg, rgba(37,157,170,1) 0%, rgba(80,195,207,1) 100%);
  padding: 0.8em 1em;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.4);
  
}

@media screen and (max-width: 600px) {
  .intro-text {
    left: 8vw;
    display: flex;
    align-self: center;
    justify-self: center;
    width: 84vw;
    box-sizing: border-box;
    height: auto;
  }
}

</style>
