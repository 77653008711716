<template>
  <div class="connect">
    <h1>Get in touch</h1>
    <h2>Follow us on social media</h2>
    <div class="social-media">
      <div class="entry">
        <a href="https://www.instagram.com/nawarak_game/" target="_blank"><img src="@/assets/instagram-logo.png"></a>
      </div>
       <div class="entry">
        <a href="https://www.tiktok.com/@nawarak_game" target="_blank"><img src="@/assets/tiktok-logo.png"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectView'
}
</script>

<style>

.connect {
  background: rgb(44,79,121);
  background: linear-gradient(0deg, rgba(44,79,121,1) 0%, rgba(152,93,108,1) 100%);
  color: #fff;
  width: 100%;
  min-height: 500px;
}

.social-media {
  display: flex;
  align-content: center;
  justify-content: center;
}

.entry img {
  width: 50px;
  height: 50px;
  margin: 20px;
}

</style>