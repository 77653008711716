<template>
  <div class="story">
    <div class="wrapper">
      <h1>Story</h1>
      <p>Nawarak is set in an extremely arid world, nearly everything is covered in sand. Heavy sandstorms constantly change the environment, covering and uncovering vital resources, forcing the people to live a nomadic life.<br> Water is a precious good, all life depends on it, but it’s very rare to find. The Mo’ols, whales that live in the clouds, are one of the last remaining sources of water and they need your help!<br>Some Mo’ols can’t put up with the heavy winds and crash down on earth, where they would die if there’s no one to rescue them. </p><p>It is your task to venture out in the dangerous and barren world to find the stranded Mo’ol, dig it out and bring it back to its herd.</p>
      <img src="@/assets/rescue.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoryView'
}
</script>

<style>

.story {
  background-color: rgb(255, 255, 255);
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 2em;
  box-sizing: border-box;
}

.story p {
  font-size: 28px;
  line-height: 1.5em;
}

</style>