<template>
  <div class="app">
  <LandingPage />
  <TrailerView />
  <StoryView />
  <FeaturesView />
  <ConnectView />
  </div>
</template>

<script>
import LandingPage from './components/LandingPage.vue'
import TrailerView from './components/TrailerView.vue'
import StoryView from './components/StoryView.vue'
import FeaturesView from './components/FeaturesView.vue'
import ConnectView from './components/ConnectView.vue'

export default {
  name: 'App',
  components: {
    LandingPage,
    TrailerView,
    StoryView,
    FeaturesView,
    ConnectView
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&display=swap');



body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow: auto;
  font-family: 'Dosis', sans-serif;
  font-size: 24px;
  overflow-x: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.wrapper {
  width: 70vw;
  align-self: center;
}

img {
  max-width: 100%;
}
</style>
