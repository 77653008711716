<template>
  <div class="features">
    <div class="wrapper">
      <h1>Features</h1>
      <div class="feature">
        <div class="feature-text">
          <h2>Caravans</h2>
          <p>Having to take care of a constantly moving settlement. Gather resources along the way and make sure everything stays on track.</p>
        </div>
        <div class="feature-image">
          <img src="@/assets/caravan.png">
        </div>
      </div>
      <div class="feature">
        <div class="feature-text">
          <h2>Sanddunes</h2>
          <p>A setting that is deeply intertwined with the games’ mechanics: Playing in a constantly changing setting where topology and resources can change within minutes of gameplay.</p>
        </div>
        <div class="feature-image">
          <img src="@/assets/dunes.png">
        </div>
      </div>
      <div class="feature">
        <div class="feature-text">
          <h2>Nonviolent Victory Condition &amp; Alternate Playstyle </h2>
          <p>We strongly believe there are more ways to win a game than just with war. Nawarak brings along new ways to interact with players and encourages them to team up together to win.</p>
        </div>
        <div class="feature-image">
          <img src="@/assets/victorycondition.png">
        </div>
      </div>
      <div class="feature">
        <div class="feature-text">
          <h2>Adjustable Playtime</h2>
          <p>Sometimes players have a lot of time to play, sometimes they don’t. Nawarak is designed in a way that allows to set a time limit. No more interruption of unfinished playing sessions.</p>
        </div>
        <div class="feature-image">
          <img src="@/assets/compass.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesView'
}
</script>

<style>

.features {
  background-color: rgb(196, 196, 196);
  width: 100vw;
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

</style>